<template>
  <el-table
    v-loading="props.loading"
    :data="listData"
    style="width: 100%"
    max-height="calc(100% - 40px)"
    :min-height="230"
    border
    stripe
    :row-class-name="disableRow"
    class="elv-reconciliation-match-rule-table"
  >
    <el-table-column :label="t('common.priority')" width="85" class-name="none-padding">
      <template #default="{ row }">
        <PriorityCell :row="row" />
      </template>
    </el-table-column>
    <el-table-column prop="name" :label="t('title.matchSetName')" width="200">
      <template #default="{ row }">
        <span class="elv-reconciliation-match-rule-table-row__cell-name">{{ formatLanguageContent(row.name) }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="t('common.conditions')" min-width="355">
      <template #default="{ row }">
        <ConditionCell :data="row" />
      </template>
    </el-table-column>
    <el-table-column :label="t('report.matches')" width="120" align="right" header-align="right">
      <template #default="{ row }">
        <router-link
          :to="{ name: 'entity-reconciliation-matched' }"
          class="elv-reconciliation-match-rule-table-row__cell-matches"
          >{{ formatNumber(row.matchCount ?? 0) }}</router-link
        >
      </template>
    </el-table-column>
    <el-table-column :label="t('title.matchSetTransactions')" width="120" align="right" header-align="right">
      <template #default="{ row }">
        <TextCell
          :text="
            fieldValueFormat(
              row.transactionCount ?? 0,
              {
                price: false,
                keepPoint: true,
                symbol: `${
                  entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )
          "
          align="right"
          :weight="500"
        />
      </template>
    </el-table-column>
    <el-table-column :label="t('title.matchSetLedger')" width="120" align="right" header-align="right">
      <template #default="{ row }">
        <TextCell
          :text="formatNumberToSignificantDigits(row.ledgerCount ?? 0, 2, '', false)"
          align="right"
          :weight="500"
        />
      </template>
    </el-table-column>
    <el-table-column :label="t('common.amount')" width="120" align="right" header-align="right">
      <template #default="{ row }">
        <TextCell
          :text="
            formatNumberToSignificantDigits(
              row.ledgerAmountFC ?? 0,
              2,
              `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            )
          "
          align="right"
          :weight="500"
        />
      </template>
    </el-table-column>
    <el-table-column :resizable="false" width="120">
      <template #default="{ row, $index }">
        <div v-if="$index !== 0" class="elv-reconciliation-match-rule-table-row__cell-button">
          <SvgIcon name="sources-edit" width="18" height="18" @click="onEditRule(row)" />
          <SvgIcon name="sources-delete" width="18" height="18" @click="onOpenDeleteConfirm(row)" />
        </div>
      </template>
    </el-table-column>
  </el-table>

  <ElvMessageBox
    ref="deleteMessageBoxRef"
    :showCancelButton="true"
    :confirmButtonText="t('button.delete')"
    :cancelButtonText="t('button.cancel')"
    :title="t('report.deleteRule')"
    :loading="deleteLoading"
    @onConfirmEvent="onConfirmDeleteMatchRule"
    @onCancelEvent="deleteMessageBoxRef?.onCheckMessageBoxDialog()"
  >
    <template #content>
      <span class="elv-confirm-counterparty">{{ t('message.deleteMatchRuleInfo') }}</span></template
    >
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import PriorityCell from './Cell/PriorityCell.vue'
import ConditionCell from './Cell/ConditionCell.vue'
import ReconciliationApi from '@/api/ReconciliationApi'
import { useEntityStore } from '@/stores/modules/entity'
import TextCell from '@/components/Base/Table/TextCell.vue'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import { formatNumber, fieldValueFormat, formatLanguageContent, formatNumberToSignificantDigits } from '@/lib/utils'

const props = defineProps({
  ruleParams: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()
const route = useRoute()

const entityStore = useEntityStore()
const deleteMessageBoxRef = useTemplateRef('deleteMessageBoxRef')
const { entityDetail } = storeToRefs(entityStore)
const reconciliationStore = useReconciliationStore()
const deleteLoading = ref(false)
const currentRule: any = ref({})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const reconciliationSetId = computed(() => {
  return String(route.params?.reconciliationSetId)
})

const emit = defineEmits(['onEditRule', 'onChangeStatus', 'onChangePage', 'onChangePageSize', 'onResetDetail'])

const listData = computed(() => {
  return reconciliationStore.reconciliationSetDetail?.ruleList
})

const disableRow = (data: any) => {
  if (!data.row.isEnabled) return 'elv-rule-table-is-disabled'
  return ''
}

const onEditRule = (row: any) => {
  emit('onEditRule', row)
}
const onConfirmDeleteMatchRule = async () => {
  try {
    deleteLoading.value = true
    await ReconciliationApi.deleteReconciliationRule(
      entityId.value,
      reconciliationSetId.value,
      currentRule.value?.reconciliationRuleId
    )
    deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
    ElMessage.success(t('message.deleteSuccess'))
    emit('onResetDetail')
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

const onOpenDeleteConfirm = (row: any) => {
  currentRule.value = row
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.elv-reconciliation-match-rule-table.el-table {
  --el-table-border-color: #ced7e0;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #d4dce6;

  .el-table__header {
    .el-table__cell {
      height: 36px;
      padding: 0;
      background: #eef4fb;
      border-bottom-color: #ced7e0;
      border-right-color: #e4e7eb;

      &:last-of-type {
        border-right: 0px;
      }

      &.is-center {
        .cell {
          justify-content: center;
        }
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        padding: 0 10px;
        height: 35px;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
      }
    }
  }

  &.el-table--border {
    .el-table__inner-wrapper {
      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__border-left-patch {
    display: none;
  }

  .el-switch {
    --el-switch-on-color: #2f63eb;
    --el-switch-off-color: #dde1e6;
    width: 38px;
    height: 18px;
    border-radius: 20px;
  }

  .el-table__row {
    &:not(.elv-rule-table-is-disabled) {
      &:hover {
        background: #f5f7fa;
      }
    }

    &:last-of-type .el-table__cell {
      border-bottom: 0px;
    }

    &.elv-rule-table-is-disabled {
      background: #f9fafb;
      opacity: 0.6;
    }

    .el-table__cell {
      padding: 0;

      &:last-of-type {
        border-right: 0px;
      }

      &.is-right .cell {
        text-align: right;
        justify-content: flex-end;
      }

      &.is-center .cell {
        text-align: center;
        justify-content: center;
      }

      &.is-number .cell {
        font-family: 'Barlow';
        font-weight: 500;
      }

      .cell {
        padding: 0 10px;
        min-height: 35px;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;
        word-break: break-word;

        .elv-reconciliation-match-rule-table-row__cell-action__title {
          color: #000;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 4px;
        }

        .elv-reconciliation-match-rule-table-row__cell-action__label {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          white-space: nowrap;
        }

        .elv-reconciliation-match-rule-table-cell-conditions__item-is {
          margin: 0 6px;
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 11px;
          line-height: 14px;
          color: #636b75;

          span:nth-child(2) {
            margin-left: 4px;
          }
        }

        .elv-reconciliation-match-rule-table-cell-conditions__item-value {
          white-space: nowrap;
        }

        .elv-reconciliation-match-rule-table-row__cell-action__value {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-left: 4px;
          white-space: nowrap;
        }
      }

      &.none-padding {
        .cell {
          padding: 0px;
        }
      }
    }
  }

  .elv-reconciliation-match-rule-table-row__cell-name {
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }

  .elv-reconciliation-match-rule-table-row__cell-matches {
    color: #0e0f11;
    font-family: 'Barlow';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
  }

  .elv-reconciliation-match-rule-table-row__cell-data {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }

  .elv-reconciliation-match-rule-table-row__cell-action {
    padding: 11px 0;
    display: flex;
    flex-direction: column;

    .elv-reconciliation-match-rule-table-cell-action__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .elv-reconciliation-match-rule-table-row__cell-conditions {
    padding: 11px 0;
    display: flex;
    flex-direction: column;

    .elv-reconciliation-match-rule-table-cell-conditions__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .elv-reconciliation-match-rule-table-cell-conditions__item-type {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      color: #0e0f11;
    }
  }

  .elv-reconciliation-match-rule-table-row__cell-actions {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #0e0f11;

    p {
      display: flex;
      align-items: center;
    }

    span {
      height: 18px;
      box-sizing: border-box;
      padding: 0 6px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      margin-left: 6px;
      color: #ffffff;
    }
  }

  .elv-reconciliation-match-rule-table-row__cell-switch {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .elv-reconciliation-match-rule-table-row__cell-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      cursor: pointer;
      fill: #838d95;

      &.is-disabled {
        cursor: not-allowed !important;
        fill: #edf0f3 !important;
      }

      &:first-of-type {
        margin-right: 8px;
      }

      &:hover {
        fill: #1e2024;
      }
    }
  }
}
</style>
