<template>
  <div v-for="(condition, index) in props.conditions" :key="index" class="elv-reconciliation-match-condition__item">
    <span class="elv-reconciliation-match-table-condition__title">
      {{ currentConditionType(condition).value }}
    </span>
    <p class="elv-reconciliation-match-condition__item-is">
      <span v-if="!['DATETIME', 'AMOUNT', 'AMOUNT_FC'].includes(condition.type)">{{ t('common.is') }}</span>
      <span v-else-if="['AMOUNT', 'AMOUNT_FC'].includes(condition.type)">
        {{ amountOperator(condition.operator).value }}</span
      >
      <span v-if="condition.type === 'DATETIME'">{{ dateOperator(condition.operator).value }}</span>
    </p>
    <div class="elv-reconciliation-match-condition__item-value">
      <template v-if="condition.type === 'TRANSACTION_TYPE'">
        <template v-if="condition.operator === 'IN'">
          <span v-for="(item, i) in condition.value" :key="i">
            <SvgIcon :name="platformContactIcon(item.split('_')[0]).value" width="10.08" height="10.08" />
            {{ transformI18n(transactionTypeData(item).value) }}</span
          >
        </template>
        <span v-else>
          <SvgIcon :name="platformContactIcon(condition.value.split('_')[0]).value" width="10.08" height="10.08" />
          {{ transformI18n(transactionTypeData(condition.value).value) }}
        </span>
      </template>
      <span v-else-if="condition.type === 'COUNTERPARTY'">{{ condition?.value?.counterparty?.name }}</span>

      <template v-else-if="['TREASURY_ACCOUNT', 'ENTITY_ACCOUNT'].includes(condition.type)">
        <template v-if="condition.operator === 'IN'">
          <template v-if="condition?.value?.length">
            <span v-for="(item, i) in condition.value" :key="i"> {{ item?.name }}</span>
          </template>
        </template>
        <span v-else>{{ condition.value?.entityAccount?.name }}</span>
      </template>

      <template v-else-if="condition.type === 'DATETIME'">
        <template v-if="condition.operator === 'BETWEEN'">
          <span
            >{{ dayjs(condition.value[0]).format('YYYY/MM/DD') }}
            -
            {{ dayjs(condition.value[1]).format('YYYY/MM/DD') }}</span
          >
        </template>
        <span v-else>{{ condition.value ? dayjs(condition.value[0]).format('YYYY/MM/DD') : '' }}</span>
      </template>

      <template v-else-if="condition.type === 'AUXILIARY'">
        <template v-if="condition?.value?.length">
          <span v-for="(item, i) in condition.value" :key="i"> {{ item?.name }}</span>
        </template>
        <span v-else>
          {{ condition.operator === 'EXCLUDES' ? 'excludes' : getAuxiliaryValue(condition.value) }}
        </span>
      </template>

      <template v-else-if="condition.type === 'CURRENCY'">
        <template v-if="condition.operator === 'IN'">
          <template v-if="condition?.underlyingCurrencyList?.length">
            <span v-for="(item, i) in condition.underlyingCurrencyList" :key="i">
              {{ item?.showSymbol }}
            </span>
          </template>
          <span v-for="(item, i) in condition.value" v-else :key="i"> {{ item?.showSymbol }}</span>
        </template>
        <span v-else>{{ find(props.currencyList, { symbol: condition.value })?.showSymbol ?? condition.value }}</span>
      </template>

      <template v-else-if="['AMOUNT', 'AMOUNT_FC'].includes(condition.type)">
        <span>{{ condition.value?.[0] }}</span>
        <span v-if="condition.value?.[1]">&nbsp;&nbsp;- {{ condition.value?.[1] }}</span>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { transformI18n } from '@/i18n/index'
import { transactionType } from '@/config/index'
import { find, flatMap, capitalize } from 'lodash-es'
import { useComputedHook } from '@/hooks/useComputedHook'

const props = defineProps({
  conditions: {
    type: Array<any>,
    default: () => {
      return []
    }
  },
  currencyList: {
    type: Array<any>,
    default: () => {
      return []
    }
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)

const { t } = useI18n()

const currentConditionType: any = useComputedHook((row: any) => {
  let label = ''
  switch (row.type) {
    case 'TRANSACTION_TYPE':
      label = t('common.type')
      break
    case 'TREASURY_ACCOUNT':
    case 'ENTITY_ACCOUNT':
      label = t('common.account')
      break
    case 'CURRENCY':
      label = t('common.currency')
      break
    case 'AMOUNT':
      label = t('common.amount')
      break
    case 'DATETIME':
      label = t('report.datetime')
      break
    case 'AUXILIARY':
      label = `Auxiliary Code.${row?.value?.auxiliaryType?.name}`
      break
    default:
      label = capitalize(row.type)
      break
  }
  return label
})

const amountOperator: any = useComputedHook((operator: string) => {
  let label = ''
  switch (operator) {
    case 'EQ':
      label = t('common.equals')
      break
    case 'NE':
      label = t('common.doesNotEqual')
      break
    case 'GTE':
      label = t('common.isGreaterThanOrEqualTo')
      break
    case 'GT':
      label = t('common.isGreaterThan')
      break
    case 'LTE':
      label = t('common.isLessThanOrEqualTo')
      break
    case 'LT':
      label = t('common.isLessThan')
      break
    case 'INCLUDES':
      label = t('common.includes')
      break
    default:
      label = t('common.isBetween')
      break
  }
  return label
})

const platformContactIcon: any = useComputedHook((type: string) => {
  let icon = ''
  switch (type) {
    case 'CHAIN':
      icon = 'blockchain'
      break
    case 'BANK':
      icon = 'temple'
      break
    case 'EXCHANGE':
      icon = 'opposite-directions'
      break
    case 'CUSTODY':
      icon = 'wallet'
      break
    case 'PAYMENT':
      icon = 'credit-card'
      break
    default:
      break
  }
  return icon
})

const transactionTypeData: any = useComputedHook((type: string) => {
  let data = ''
  const transactionTypeList = flatMap(transactionType, 'list')
  transactionTypeList.forEach((item) => {
    if (item.value === type) {
      data = item.label
    }
  })
  return data
})

const dateOperator: any = useComputedHook((operator: string) => {
  let label = ''
  switch (operator) {
    case 'EQ':
    case 'ON':
      label = t('common.on')
      break
    case 'GT':
    case 'BEFORE':
      label = t('common.before')
      break
    case 'LT':
    case 'AFTER':
      label = t('common.after')
      break
    default:
      label = t('common.isBetween')
      break
  }
  return label
})

const getAuxiliaryValue = (item: any) => {
  if (item.auxiliaryType.type === 'ENTITY_ACCOUNT') {
    return item?.entityAccount?.name
  }

  if (item.auxiliaryType.type === 'COUNTERPARTY') {
    return item?.counterparty?.name
  }

  if (item.auxiliaryType.type === 'ITEM') {
    return item.auxiliaryItem?.value
  }

  return null
}
</script>

<style lang="scss" scoped>
.elv-reconciliation-match-condition__item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}

.elv-reconciliation-match-table-condition__title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  color: #636b75;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 14px;
  text-transform: capitalize;
}

.elv-reconciliation-match-condition__item-is {
  margin-right: 4px;
  margin-left: 4px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #636b75;

  span:nth-child(2) {
    margin-left: 4px;
  }
}

.elv-reconciliation-match-condition__item-value {
  width: 414px;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 4px 8px;
  flex: 1 0 0;
  flex-wrap: wrap;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  color: #0e0f11;
  font-size: 11px;
  letter-spacing: 0;

  span {
    display: flex;
    align-items: center;

    svg {
      margin-right: 4px;
    }
  }
}
</style>
