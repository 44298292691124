<template>
  <el-dialog
    v-model="show"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-match-set-filter-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-match-set-filter-dialog-header__title">
        {{ props.title }}
      </h4>
    </template>
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-position="top">
      <el-form-item label=" " class="elv-match-set-filter-form-item-line condition">
        <div v-if="show" class="elv-rule-form-condition">
          <Conditions
            v-for="(item, index) in conditionList"
            :key="item.index"
            :index="index"
            :condition="item"
            :type="props.type"
            :chartOfAccount="props.chartOfAccount"
            :entityAccountAuxiliaryTypeId="props.entityAccountAuxiliaryTypeId"
            :currencyList="props.currencyList"
            :conditionList="conditionList"
            @addCondition="addCondition"
            @onConditionChange="onConditionChange"
          />
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button
        type="primary"
        round
        class="elv-match-set-filter-dialog-footer__save"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="onClickConnect"
        >{{ t('button.save') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { CurrencyItemType } from '#/ReportsTypes'
import Conditions from './FilterConditions/Index.vue'
import ReconciliationApi from '@/api/ReconciliationApi'
import type { FormInstance, FormRules } from 'element-plus'
import { pick, isArray, filter, isEmpty, cloneDeep } from 'lodash-es'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: ''
  },
  currencyList: {
    type: Array<CurrencyItemType>,
    required: true
  },
  chartOfAccount: {
    type: Object as () => Record<string, any>,
    default: () => {
      return {}
    }
  },
  entityAccountAuxiliaryTypeId: {
    type: String,
    default: ''
  },
  conditionsData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const route = useRoute()
const emit = defineEmits(['onResetDetail'])

const submitLoading = ref(false)
const show = defineModel('show', { type: Boolean, required: true })

const ruleForm = ref({})
const ruleFormRef = useTemplateRef<FormInstance>('ruleFormRef')

const rules = reactive<FormRules>({
  name: {
    required: true,
    trigger: 'blur',
    message: 'Rule Name is required'
  },
  conditions: {
    required: true,
    trigger: 'blur',
    message: 'Conditions is required'
  }
})

const conditionList = ref([
  {
    index: '0',
    operator: 'EQ',
    type: '',
    value: ''
  }
])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const reconciliationSetId = computed(() => {
  return String(route.params?.reconciliationSetId)
})

const conditionValueFormat = computed(() => {
  return (data: any) => {
    if (data.type === 'DATETIME' && data.operator !== 'BETWEEN') {
      return isArray(data.value) ? data.value : [data.value]
    }
    if (data.type === 'AMOUNT' || data.type === 'AMOUNT_FC') {
      return data.value.map((item: any) => Number(item))
    }
    if (data.type === 'ENTITY_ACCOUNT') {
      return data.value?.entityAccountIds?.map((item: any) => {
        return { entityAccountId: item }
      })
    }
    if (data.type === 'COUNTERPARTY') {
      return data.value?.counterpartyIds.map((item: any) => {
        return { counterpartyId: item }
      })
    }
    if (data.type === 'AUXILIARY') {
      if (
        data.value?.entityAccountId === 'NOT_SET' ||
        data.value?.auxiliaryItemId === 'NOT_SET' ||
        data.value?.counterpartyId === 'NOT_SET'
      ) {
        return {
          auxiliaryTypeId: data.value.auxiliaryTypeId
        }
      }
      if (data.value?.entityAccountId !== '') {
        return {
          entityAccountId: data.value.entityAccountId,
          auxiliaryTypeId: data.value.auxiliaryTypeId
        }
      }

      if (data.value?.counterpartyId !== '') {
        return {
          counterpartyId: data.value.counterpartyId,
          auxiliaryTypeId: data.value.auxiliaryTypeId
        }
      }
      if (data.value?.auxiliaryItemId !== '') {
        return {
          auxiliaryTypeId: data.value.auxiliaryTypeId,
          auxiliaryItemId: data.value.auxiliaryItemId
        }
      }

      return data.value
      // if (data.value.some((item: any) => item === 'NOT_SET')) {
      //   return [{ noSet: true }]
      // }
      // return data.value.map((item: any) => {
      //   return { entityAccountId: item }
      // })
    }
    return data.value
  }
})

const saveData = computed(() => {
  const newList = conditionList.value.map((item: any) => {
    return pick(item, ['operator', 'value', 'type', 'auxiliaryTypeId'])
  })
  const conditions = newList.map((item: any) => {
    return {
      ...item,
      operator: item.operator !== '' ? item.operator : 'IN',
      value: conditionValueFormat.value(item)
    }
  })
  return conditions.length === 1 && !conditions[0].value ? [] : conditions
})

const saveDisabled = computed(() => {
  const encryptionIds = ['CURRENCY', 'TRANSACTION_TYPE', 'ENTITY_ACCOUNT', 'COUNTERPARTY']
  const isConditionList = conditionList.value.every((item: any) => {
    if (encryptionIds.includes(item.type)) {
      const keys = Object.keys(item.value)
      return item.type && item.operator && !isEmpty(item.value[keys?.[0]])
    }
    if (['AMOUNT', 'AMOUNT_FC'].includes(item.type) || item.type === 'DATETIME') {
      if (item.operator === 'BETWEEN') {
        return item.type && item.operator && item.value && item.value[0] !== '' && item.value[1] !== ''
      }
      return (
        item.type &&
        item.operator &&
        ((['AMOUNT', 'AMOUNT_FC'].includes(item.type) && item.value[0] !== '') ||
          (item.type === 'DATETIME' && item.value !== ''))
      )
    }
    if (item.type === 'AUXILIARY') {
      return (
        item.type &&
        item.operator &&
        (item.value?.entityAccountId || item.value?.counterpartyId || item.value?.auxiliaryItemId) &&
        item.value?.auxiliaryTypeId
      )
    }
    return item.type && item.operator && item.value
  })
  return (
    (!isConditionList && !props.conditionsData.length && !saveData.value.length) ||
    (!isConditionList && !props.conditionsData.length && !!saveData.value.length) ||
    (!!props.conditionsData.length && !!saveData.value.length && !isConditionList)
  )
})

const onCloseDialog = () => {
  ruleFormRef.value?.resetFields()
  conditionList.value = [
    {
      index: '0',
      operator: 'EQ',
      type: '',
      value: ''
    }
  ]
}

const onClickConnect = async () => {
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        submitLoading.value = true
        const newList = conditionList.value.map((item: any) => {
          return pick(item, ['operator', 'value', 'type', 'auxiliaryTypeId'])
        })
        const conditions = newList.map((item: any) => {
          return {
            ...item,
            operator: item.operator !== '' ? item.operator : 'IN',
            value: conditionValueFormat.value(item)
          }
        })
        const params = {
          [`${props.type}Conditions`]: conditions.length === 1 && !conditions[0].value ? [] : conditions
        }
        await ReconciliationApi.updateReconciliationSet(entityId.value, reconciliationSetId.value, params)
        ElMessage.success(t('message.editSuccess'))
        emit('onResetDetail')
        show.value = false
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

const addCondition = (index: number) => {
  if (
    (props.type === 'transaction' && conditionList.value.length < 5) ||
    (props.type === 'ledger' && conditionList.value.length < 4)
  ) {
    conditionList.value.splice(index + 1, 0, {
      index: `${conditionList.value.length}`,
      operator: 'EQ',
      type: '',
      value: ''
    })
  }
}

// emit 事件
const onConditionChange = (index: number, condition: any, operating: string = 'edit') => {
  if (operating === 'edit') {
    const id = conditionList.value[index].index
    conditionList.value[index] = condition
    conditionList.value[index].index = id
  } else if (operating === 'reset') {
    conditionList.value[index] = {
      index: `${index}`,
      operator: 'EQ',
      type: '',
      value: ''
    }
  } else {
    const newList = filter(conditionList.value, (item: any, i: number) => i !== index)
    conditionList.value = newList
  }
}

watch(
  [() => props.conditionsData, () => show.value],
  async () => {
    if (!isEmpty(props.conditionsData) && show.value) {
      const currentData = cloneDeep(props.conditionsData)
      conditionList.value = currentData.map((item: any, index: number) => {
        const value = item.operator === 'EQ' && item.type !== 'DATETIME' ? [item.value] : item.value
        const condition: any = {
          index: `${index}`,
          operator: item.operator,
          type: item.type,
          value: item.operator !== 'BETWEEN' && item.type === 'DATETIME' ? item.value[0] : value
        }
        if (item.type === 'CURRENCY') {
          condition.underlyingCurrencyList = item.value
        }
        if (item.type === 'AUXILIARY') {
          // condition.auxiliaryTypeId = item?.auxiliaryTypeId ?? ''
          // if (condition.value?.[0] === 'NOT_SET') {
          //   condition.value = ['NOT_SET']
          // } else if (condition.value?.length) {
          //   condition.value = condition.value.map((i: any) => i.entityAccountId)
          // }
          if (item.operator === 'EXCLUDES') {
            condition.value = {
              entityAccountId: item.value?.auxiliaryType?.type === 'ENTITY_ACCOUNT' ? 'NOT_SET' : '',
              counterpartyId: item.value?.auxiliaryType?.type === 'COUNTERPARTY' ? 'NOT_SET' : '',
              auxiliaryItemId: item.value?.auxiliaryType?.type === 'ITEM' ? 'NOT_SET' : '',
              auxiliaryTypeId: item.value.auxiliaryTypeId
            }
          } else {
            condition.value = {
              entityAccountId: item.value?.auxiliaryType?.type === 'ENTITY_ACCOUNT' ? item.value?.entityAccountId : '',
              counterpartyId: item.value?.auxiliaryType?.type === 'COUNTERPARTY' ? item.value?.counterpartyId : '',
              auxiliaryItemId: item.value?.auxiliaryType?.type === 'ITEM' ? item.value?.auxiliaryItemId : '',
              auxiliaryTypeId: item.value.auxiliaryTypeId
            }
          }
        }
        return condition
      })
    } else if (isEmpty(props.conditionsData) && show.value) {
      conditionList.value = [
        {
          index: '0',
          operator: 'EQ',
          type: '',
          value: ''
        }
      ]
    }
  },
  { deep: true, immediate: true }
)
</script>

<style lang="scss">
.elv-match-set-filter-dialog {
  width: 761px;
  min-height: 100px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-match-set-filter-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .el-input {
      width: 802px;
      height: 36px;
      border-radius: 4px;

      &.elv-rule-form-name {
        height: 44px;
      }

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      &.elv-match-set-filter-form-item-line {
        .el-form-item__label {
          position: relative;
          color: #838d95;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }

      .elv-rule-form-reviewed {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;

        .el-switch {
          --el-switch-on-color: #2f63eb;
          --el-switch-off-color: #dde1e6;
          width: 38px;
          height: 18px;
          border-radius: 20px;
          margin-left: 10px;
        }
      }

      .elv-rule-form-condition {
        width: 713px;
        min-height: 52px;
        box-sizing: border-box;
        // :nth-last-child(1) {
        //   margin-bottom: 0px;
        // }
        .elv-match-set-filter-conditions:last-child {
          margin-bottom: 0px;
        }
      }

      .elv-rule-form-event {
        width: 100%;
        height: 94px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        background: #f9fafb;
        border-radius: 5px;

        .elv-rule-form-condition-title {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 100%;
          padding-bottom: 8px;
          border-bottom: 1px solid #edf0f3;
          margin-bottom: 8px;
        }
      }
    }

    .el-select {
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-source-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;

      &.is-disabled {
        background: #edf0f3;
        color: #838d95;
      }
    }
  }
}
</style>
