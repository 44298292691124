<template>
  <el-dialog
    v-model="show"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-match-rule-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-match-rule-dialog-header__title">
        {{ props.model === 'add' ? t('button.addRule') : t('button.editRule') }}
      </h4>
    </template>
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-position="top">
      <el-form-item :label="t('common.ruleName')" prop="name">
        <el-input v-model="ruleForm.name" :placeholder="t('message.pleaseInput')" class="elv-rule-form-name" />
      </el-form-item>

      <el-form-item :label="t('common.conditions')" class="elv-match-rule-form-item-line condition">
        <div v-if="show" class="elv-rule-form-condition">
          <div class="elv-rule-form-condition-header">
            <div class="elv-rule-form-condition-header-title">
              <div>{{ t('report.Transactions') }}:</div>
              <div>{{ t('valuation.tolerance') }}</div>
              <div>{{ t('common.ledger') }}</div>
              <div></div>
            </div>
            <div class="elv-rule-form-condition-header-placeholder">
              <div>Per Transaction</div>
              <div></div>
              <div>Per Ledger</div>
              <div></div>
            </div>
          </div>
          <template v-if="conditionList.length">
            <div v-for="item in conditionList" :key="item.index" class="elv-rule-form-condition-item">
              <div class="elv-rule-form-condition-item-transaction">
                <el-select
                  v-model="item.transactionField"
                  placeholder="Select ..."
                  @change="onChangeTransactionField(item.index)"
                >
                  <el-option
                    v-for="option in transactionOptions"
                    :key="option.value"
                    :disabled="findIndex(conditionList, (i: any) => i.transactionField === option.value) !== -1"
                    :label="transformI18n(option.label)"
                    :value="option.value"
                  />
                </el-select>
              </div>
              <div class="elv-rule-form-condition-item-tolerance">
                <SingleChoiceSelect
                  v-model="item.type"
                  placeholder="Select ..."
                  width="304px"
                  :disabled="
                    item.transactionField === '' ||
                    item.transactionField === 'TRANSACTION_HASH' ||
                    item.transactionField === 'ENTITY_ACCOUNT' ||
                    item.ledgerField === '' ||
                    item.ledgerField === 'AUXILIARY' ||
                    item.ledgerField === 'REFERENCE_NO' ||
                    item.ledgerField === 'ADDITIONAL_ITEM'
                  "
                  :options="toleranceOptions"
                />
                <template
                  v-if="
                    (['DATETIME', 'AMOUNT'].includes(item.transactionField) ||
                      ['DATETIME', 'AMOUNT'].includes(item.ledgerField)) &&
                    item.type === 'TOLERANCE'
                  "
                >
                  <div class="elv-rule-form-condition-item-tolerance__item">
                    <el-select :model-value="true">
                      <el-option
                        :label="item.transactionField === 'DATETIME' ? 'Lower limit (days)' : 'Lower limit'"
                        :value="true"
                      />
                    </el-select>
                    <el-input v-model="item.lowerLimit" placeholder=" " width="80px" />
                  </div>
                  <div class="elv-rule-form-condition-item-tolerance__item">
                    <el-select :model-value="true">
                      <el-option
                        :label="item.transactionField === 'DATETIME' ? 'Upper limit (days)' : 'Upper limit'"
                        :value="true"
                      />
                    </el-select>
                    <el-input v-model="item.upperLimit" placeholder=" " width="80px" />
                  </div>
                </template>
              </div>
              <div class="elv-rule-form-condition-item-ledger">
                <el-select
                  v-model="item.ledgerField"
                  placeholder="Select ..."
                  :style="{ width: item.ledgerField === 'ADDITIONAL_ITEM' ? '170px' : '260px' }"
                  @change="onChangeLedgerField(item.index)"
                >
                  <el-option
                    v-for="option in ledgerOptions"
                    :key="option.value"
                    :disabled="
                      findIndex(conditionList, (i: any) => i.ledgerField === option.value) !== -1 ||
                      (['REFERENCE_NO', 'ADDITIONAL_ITEM'].includes(option.value) &&
                        findIndex(conditionList, (i: any) => i.transactionField === 'TRANSACTION_HASH') !== -1 &&
                        findIndex(conditionList, (i: any) => i.transactionField === 'TRANSACTION_HASH') !==
                          Number(item.index))
                    "
                    :label="transformI18n(option.label)"
                    :value="option.value"
                  />
                </el-select>
                <el-input
                  v-if="item.ledgerField === 'ADDITIONAL_ITEM'"
                  v-model="item.additionalItemPath"
                  placeholder="Path"
                />
              </div>
              <div class="elv-rule-form-condition-item-delete">
                <SvgIcon
                  name="sources-delete"
                  width="16"
                  height="16"
                  :class="{
                    'is-disabled': item.index === '0' && (item.ledgerField === '' || item.transactionField === '')
                  }"
                  @click="deleteCondition(item.index)"
                />
              </div>
            </div>
          </template>
        </div>
      </el-form-item>

      <div class="elv-reconciliation-match-rule-add-condition" @click="addCondition">
        <SvgIcon name="add-reports" width="16" height="16" />
        <div>Add Condition</div>
      </div>
    </el-form>

    <template #footer>
      <el-button
        type="primary"
        round
        class="elv-match-rule-dialog-footer__save"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="onClickConnect"
        >{{ t('button.save') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { $t, transformI18n } from '@/i18n/index'
import ReconciliationApi from '@/api/ReconciliationApi'
import type { FormInstance, FormRules } from 'element-plus'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'
import { find, findIndex, pick, filter, isEmpty, cloneDeep } from 'lodash-es'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  entityAccountAuxiliaryTypeId: {
    type: String,
    default: ''
  },
  chartOfAccount: {
    type: Object as () => Record<string, any>,
    default: () => {
      return {}
    }
  },
  currentRuleData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const route = useRoute()
const emit = defineEmits(['onResetDetail'])
const show = defineModel('show', { type: Boolean, required: true })

const submitLoading = ref(false)
const ruleForm = ref({
  name: ''
})
const ruleFormRef = useTemplateRef<FormInstance>('ruleFormRef')

const rules = reactive<FormRules>({
  name: {
    required: true,
    trigger: 'blur',
    message: 'Rule Name is required'
  },
  conditions: {
    required: true,
    trigger: 'blur',
    message: 'Conditions is required'
  }
})

const conditionList = ref([
  {
    index: '0',
    type: '',
    transactionField: '',
    ledgerField: '',
    lowerLimit: '',
    upperLimit: '',
    limitUnit: '',
    auxiliaryTypeId: '',
    additionalItemPath: ''
  }
])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const reconciliationSetId = computed(() => {
  return String(route.params?.reconciliationSetId)
})

const transactionOptions = computed(() => {
  const list = [
    {
      label: 'Transaction ID',
      value: 'TRANSACTION_HASH'
    },
    {
      label: $t('common.amount'),
      value: 'AMOUNT'
    },
    {
      label: $t('common.date'),
      value: 'DATETIME'
    }
  ]
  if (
    !isEmpty(props.chartOfAccount) &&
    props.chartOfAccount?.auxiliaryTypeIds.length &&
    find(props.chartOfAccount?.auxiliaryTypeIds, (i: any) => i === props.entityAccountAuxiliaryTypeId)
  ) {
    list.push({
      label: $t('common.account'),
      value: 'ENTITY_ACCOUNT'
    })
  }
  return list
})

const toleranceOptions = reactive([
  {
    label: 'Tolerance',
    value: 'TOLERANCE'
  },
  {
    label: 'Equals',
    value: 'EQUALS'
  }
])

const ledgerOptions = computed(() => {
  const list = [
    {
      label: $t('common.amount'),
      value: 'AMOUNT'
    },
    {
      label: $t('common.date'),
      value: 'DATETIME'
    },
    {
      label: $t('title.additionalItem'),
      value: 'ADDITIONAL_ITEM'
    },
    {
      label: 'Reference No.',
      value: 'REFERENCE_NO'
    }
  ]
  if (
    !isEmpty(props.chartOfAccount) &&
    props.chartOfAccount?.auxiliaryTypeIds.length &&
    find(props.chartOfAccount?.auxiliaryTypeIds, (i: any) => i === props.entityAccountAuxiliaryTypeId)
  ) {
    list.push({
      label: 'Auxiliary.Account',
      value: 'AUXILIARY'
    })
  }
  return list
})

const onChangeTransactionField = (index: string) => {
  const item = conditionList.value[Number(index)]
  item.type = 'EQUALS'
  item.additionalItemPath = ''
  item.lowerLimit = ''
  item.upperLimit = ''
  item.auxiliaryTypeId = ''
  switch (item.transactionField) {
    case 'AMOUNT':
      item.ledgerField = 'AMOUNT'
      break
    case 'DATETIME':
      item.ledgerField = 'DATETIME'
      break
    case 'TRANSACTION_HASH':
      item.ledgerField = 'ADDITIONAL_ITEM'
      break
    case 'ENTITY_ACCOUNT':
      item.ledgerField = 'AUXILIARY'
      item.auxiliaryTypeId = props.entityAccountAuxiliaryTypeId
      break
    default:
      break
  }
}

const onChangeLedgerField = (index: string) => {
  const item = conditionList.value[Number(index)]
  item.additionalItemPath = ''
  item.lowerLimit = ''
  item.upperLimit = ''
  item.auxiliaryTypeId = ''
  item.type = 'EQUALS'
  switch (item.ledgerField) {
    case 'AMOUNT':
      item.transactionField = 'AMOUNT'
      break
    case 'DATETIME':
      item.transactionField = 'DATETIME'
      break
    case 'ADDITIONAL_ITEM':
    case 'REFERENCE_NO':
      item.transactionField = 'TRANSACTION_HASH'
      break
    case 'AUXILIARY':
      item.transactionField = 'ENTITY_ACCOUNT'
      item.auxiliaryTypeId = props.entityAccountAuxiliaryTypeId
      break
    default:
      break
  }
}

const saveDisabled = computed(() => {
  const isConditionList = conditionList.value.every((item: any) => {
    if (item.transactionField === 'TRANSACTION_HASH' && item.ledgerField === 'ADDITIONAL_ITEM') {
      return item.type && item.additionalItemPath && item.ledgerField && item.transactionField
    }
    if ((item.transactionField === 'DATETIME' || item.transactionField === 'AMOUNT') && item.type === 'TOLERANCE') {
      return item.type && item.lowerLimit && item.upperLimit && item.ledgerField && item.transactionField
    }
    return item.type && item.ledgerField && item.transactionField
  })
  return !ruleForm.value.name || !isConditionList || !conditionList.value.length
})

const onCloseDialog = () => {
  ruleFormRef.value?.resetFields()
  conditionList.value = [
    {
      index: '0',
      type: '',
      transactionField: '',
      ledgerField: '',
      lowerLimit: '',
      upperLimit: '',
      limitUnit: '',
      auxiliaryTypeId: '',
      additionalItemPath: ''
    }
  ]
}

const onClickConnect = async () => {
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        submitLoading.value = true
        const toleranceList = conditionList.value.map((item: any) => {
          const keys = ['transactionField', 'ledgerField', 'type']
          if (item.transactionField === 'DATETIME' || item.transactionField === 'AMOUNT') {
            keys.push('limitUnit')
            if (item.type === 'TOLERANCE') {
              keys.push('lowerLimit', 'upperLimit')
            }
          }
          if (item.transactionField === 'TRANSACTION_HASH' && item.ledgerField === 'ADDITIONAL_ITEM') {
            keys.push('additionalItemPath')
          }
          if (item.transactionField === 'ENTITY_ACCOUNT' && item.ledgerField === 'AUXILIARY') {
            keys.push('auxiliaryTypeId')
          }
          const tolerance = pick(item, keys)
          if (tolerance?.transactionField === 'AMOUNT') {
            tolerance.limitUnit = 'NUMBER'
          }
          return tolerance
        })
        const params = {
          name: ruleForm.value.name,
          toleranceList
        }
        if (props.model === 'edit') {
          await ReconciliationApi.updateReconciliationRule(
            entityId.value,
            reconciliationSetId.value,
            props.currentRuleData?.reconciliationRuleId,
            params
          )
          ElMessage.success(t('message.editSuccess'))
        } else {
          await ReconciliationApi.createReconciliationRule(entityId.value, reconciliationSetId.value, params)
          ElMessage.success(t('message.ruleSavedSuccessfully'))
        }
        emit('onResetDetail')
        ruleFormRef.value?.resetFields()
        show.value = false
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

const addCondition = () => {
  if (conditionList.value.length >= 4) {
    ElMessage.error('The maximum number of conditions is 4')
    return
  }
  conditionList.value.splice(conditionList.value.length + 1, 0, {
    index: `${conditionList.value.length}`,
    type: '',
    transactionField: '',
    ledgerField: '',
    lowerLimit: '',
    upperLimit: '',
    limitUnit: '',
    auxiliaryTypeId: '',
    additionalItemPath: ''
  })
}

const deleteCondition = (index: string) => {
  const condition = conditionList.value.find((item: any) => item.index === index)
  if (index === '0' && (condition?.ledgerField === '' || condition?.transactionField === '')) return
  const newList = filter(conditionList.value, (item: any) => item.index !== index)
  newList.forEach((item: any, i: number) => {
    item.index = `${i}`
  })
  conditionList.value = newList
}

watch(
  [() => props.currentRuleData, () => show.value],
  async () => {
    if (!isEmpty(props.currentRuleData) && show.value) {
      const currentData = cloneDeep(props.currentRuleData)
      ruleForm.value.name = currentData.name
      conditionList.value = currentData.toleranceList.map((item: any, index: number) => {
        return {
          index,
          type: item.type ?? '',
          transactionField: item.transactionField ?? '',
          ledgerField: item.ledgerField ?? '',
          lowerLimit: item.lowerLimit ?? '',
          upperLimit: item.upperLimit ?? '',
          auxiliaryTypeId: item.auxiliaryTypeId ?? '',
          additionalItemPath: item.additionalItemPath ?? ''
        }
      })
    } else if (isEmpty(props.currentRuleData) && show.value) {
      ruleForm.value.name = ''
      conditionList.value = [
        {
          index: '0',
          type: '',
          transactionField: '',
          ledgerField: '',
          lowerLimit: '',
          upperLimit: '',
          limitUnit: '',
          auxiliaryTypeId: '',
          additionalItemPath: ''
        }
      ]
    }
  },
  { deep: true, immediate: true }
)
</script>

<style lang="scss">
.elv-match-rule-dialog {
  width: 1200px;
  min-height: 200px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-match-rule-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .el-input {
      height: 44px;
      border-radius: 4px;

      &.elv-rule-form-name {
        height: 44px;
      }

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      &.elv-match-rule-form-item-line {
        .el-form-item__label {
          position: relative;
          color: #838d95;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 704px;
            height: 1px;
            background-color: #dde1e6;
          }
        }

        &.condition .el-form-item__label::after {
          width: 1078px;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }

      .elv-rule-form-reviewed {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;

        .el-switch {
          --el-switch-on-color: #2f63eb;
          --el-switch-off-color: #dde1e6;
          width: 38px;
          height: 18px;
          border-radius: 20px;
          margin-left: 10px;
        }
      }

      .elv-rule-form-condition {
        width: 1152px;
        min-height: 52px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #ced7e0;

        .elv-match-rule-conditions:last-child {
          margin-bottom: 0px;
        }

        .elv-rule-form-condition-header {
          display: flex;
          flex-direction: column;
          background-color: #f9fafb;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;

          .elv-rule-form-condition-header-title,
          .elv-rule-form-condition-header-placeholder {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            border-bottom: 1px solid #dde1e6;

            div {
              width: 392.5px;
              height: 42px;
              display: flex;
              padding: 12px 8px;
              box-sizing: border-box;
              align-items: center;
              gap: 10px;
              border-right: 1px solid #dde1e6;
              align-self: stretch;
              color: #1e2024;
              font-family: 'Plus Jakarta Sans';
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;

              &:nth-of-type(2) {
                width: 320px;
                justify-content: center;
              }

              &:last-of-type {
                width: 45px;
                border-right: none;
              }
            }
          }

          .elv-rule-form-condition-header-placeholder div {
            height: 32px;
            color: #636b75;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }

        .elv-rule-form-condition-item {
          display: flex;
          min-height: 52px;
          box-sizing: border-box;
          border-bottom: 1px solid #dde1e6;

          &:last-of-type {
            border-bottom: none;
          }

          > div {
            display: flex;
            padding: 8px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            box-sizing: border-box;
            min-height: 52px;
            border-right: 1px solid #dde1e6;

            &:last-of-type {
              border-right: none;
            }
          }

          .elv-rule-form-condition-item-transaction,
          .elv-rule-form-condition-item-ledger {
            width: 392.5px;
          }

          .elv-rule-form-condition-item-transaction {
            .el-select {
              width: 260px;
            }
          }

          .elv-rule-form-condition-item-ledger {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .el-input,
            .el-input__wrapper {
              width: 170px;
            }
          }

          .elv-rule-form-condition-item-tolerance {
            width: 320px;

            .elv-rule-form-condition-item-tolerance__item {
              display: flex;
              gap: 8px;
              align-items: center;
              justify-content: center;

              .el-input,
              .el-input__wrapper {
                width: 80px;
              }

              .el-select__wrapper {
                width: 216px;
              }
            }
          }

          .elv-rule-form-condition-item-delete {
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              fill: #838d95;
              cursor: pointer;

              &:hover {
                fill: #1753eb;
              }

              &.is-disabled {
                fill: #a8abb2;
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }

    .el-select {
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-source-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }

    .elv-reconciliation-match-rule-add-condition {
      display: flex;
      align-items: center;
      margin-top: 10px;
      cursor: pointer;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      color: #1753eb;
      font-size: 13px;
      line-height: 24px;
      width: fit-content;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        fill: #1753eb;
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;

      &.is-disabled {
        background: #edf0f3;
        color: #838d95;
      }
    }
  }
}

.el-popper.elv-journal-type-drawer-category-popper {
  width: fit-content !important;
  padding: 0px;
  min-height: 280px;
  height: fit-content;
  box-sizing: border-box;

  .elv-transactions-drawer-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}
</style>
