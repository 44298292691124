<template>
  <div v-loading="loading" class="elv-reconciliation-match-detail-page">
    <Header />
    <HeaderTabs />
    <section class="elv-reconciliation-match-content">
      <div class="elv-reconciliation-match-information">
        <div class="elv-reconciliation-match-table">
          <div class="elv-reconciliation-match-table-header">
            <div class="elv-reconciliation-match-table-header__title">
              <p>{{ t('report.Transactions') }} ({{ t('common.cashBasis') }}):</p>
              <span>
                {{ t('common.direction') }}:
                {{ reconciliationSetDetail?.direction === 'IN' ? t('report.inflow') : t('report.outflow') }}</span
              >
            </div>
            <div class="elv-reconciliation-match-table-header__total">
              <div class="elv-reconciliation-match-table-header__total-label">{{ t('common.total') }}:</div>
              <div class="elv-reconciliation-match-table-header__total-value">
                {{ formatNumber(reconciliationSetDetail?.transactionStat?.totalCount ?? 0) }}
                <span v-if="reconciliationSetDetail?.transactionStat?.totalAmountFC"
                  >({{
                    formatNumberToSignificantDigits(
                      reconciliationSetDetail?.transactionStat?.totalAmountFC,
                      2,
                      `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    )
                  }})
                </span>
              </div>
            </div>
          </div>
          <div class="elv-reconciliation-match-table-body">
            <div class="elv-reconciliation-match-table-body__title">
              <div class="elv-reconciliation-match-table-body__title-item">
                <span>{{ t('report.matched') }}:&nbsp;</span>
                <router-link :to="{ name: 'entity-reconciliation-matched' }">{{
                  formatNumber(reconciliationSetDetail?.transactionStat?.matchCount ?? 0)
                }}</router-link>
                <p v-if="reconciliationSetDetail?.transactionStat?.matchAmountFC">
                  &nbsp;({{
                    formatNumberToSignificantDigits(
                      reconciliationSetDetail?.transactionStat?.matchAmountFC,
                      2,
                      `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    )
                  }})
                </p>
              </div>
              <div class="elv-reconciliation-match-table-body__title-item">
                <span class="span">{{ t('report.unmatched') }}:&nbsp;</span>
                <router-link :to="{ name: 'entity-reconciliation-unmatched' }">{{
                  formatNumber(reconciliationSetDetail?.transactionStat?.unmatchedCount ?? 0)
                }}</router-link>
                <p v-if="reconciliationSetDetail?.transactionStat?.unmatchedAmountFC">
                  &nbsp;({{
                    formatNumberToSignificantDigits(
                      reconciliationSetDetail?.transactionStat?.unmatchedAmountFC,
                      2,
                      `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    )
                  }})
                </p>
              </div>
            </div>
            <div
              v-if="reconciliationSetDetail?.transactionConditions?.length"
              class="elv-reconciliation-match-table-body-conditions"
            >
              <FilterConditions
                :conditions="reconciliationSetDetail?.transactionConditions"
                :currency-list="reconciliationSetDetail?.underlyingCurrencyList"
              />
            </div>
            <div class="elv-reconciliation-match-table-button" @click="onEditFilter('transaction')">
              <SvgIcon name="add-reports" width="16" height="16" />
              <div>Add/Edit filter</div>
            </div>
          </div>
        </div>
        <div class="elv-reconciliation-match-table">
          <div class="elv-reconciliation-match-table-header">
            <div class="elv-reconciliation-match-table-header__title">
              <p>{{ t('common.accountingLedger') }} ({{ t('common.accrualBasis') }}):</p>
              <span>
                {{ t('report.Account') }}: {{ reconciliationSetDetail?.chartOfAccount?.name }} ({{
                  reconciliationSetDetail?.direction === 'IN' ? 'Dr' : 'Cr'
                }})
              </span>
            </div>
            <div class="elv-reconciliation-match-table-header__total">
              <div class="elv-reconciliation-match-table-header__total-label">{{ t('common.total') }}:</div>
              <div class="elv-reconciliation-match-table-header__total-value">
                {{ formatNumber(reconciliationSetDetail?.ledgerStat?.totalCount ?? 0) }}
                <span v-if="reconciliationSetDetail?.ledgerStat?.totalAmountFC"
                  >({{
                    formatNumberToSignificantDigits(
                      reconciliationSetDetail?.ledgerStat?.totalAmountFC,
                      2,
                      `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    )
                  }})
                </span>
              </div>
            </div>
          </div>
          <div class="elv-reconciliation-match-table-body">
            <div class="elv-reconciliation-match-table-body__title">
              <div class="elv-reconciliation-match-table-body__title-item">
                <span class="span">{{ t('report.matched') }}:&nbsp;</span>
                <router-link :to="{ name: 'entity-reconciliation-matched' }">{{
                  formatNumber(reconciliationSetDetail?.ledgerStat?.matchCount ?? 0)
                }}</router-link>
                <p v-if="reconciliationSetDetail?.ledgerStat?.matchAmountFC">
                  &nbsp;({{
                    formatNumberToSignificantDigits(
                      reconciliationSetDetail?.ledgerStat?.matchAmountFC,
                      2,
                      `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    )
                  }})
                </p>
              </div>
              <div class="elv-reconciliation-match-table-body__title-item">
                <span class="span">{{ t('report.unmatched') }}:&nbsp;</span>
                <router-link :to="{ name: 'entity-reconciliation-unmatched' }">{{
                  formatNumber(reconciliationSetDetail?.ledgerStat?.unmatchedCount ?? 0)
                }}</router-link>
                <p v-if="reconciliationSetDetail?.ledgerStat?.unmatchedAmountFC">
                  &nbsp;({{
                    formatNumberToSignificantDigits(
                      reconciliationSetDetail?.ledgerStat?.unmatchedAmountFC,
                      2,
                      `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    )
                  }})
                </p>
              </div>
            </div>
            <div
              v-if="reconciliationSetDetail?.ledgerConditions?.length"
              class="elv-reconciliation-match-table-body-conditions"
            >
              <FilterConditions
                :conditions="reconciliationSetDetail?.ledgerConditions"
                :currencyList="reconciliationSetDetail?.underlyingCurrencyList"
              />
            </div>
            <div class="elv-reconciliation-match-table-button" @click="onEditFilter('ledger')">
              <SvgIcon name="add-reports" width="16" height="16" />
              <div>Add/Edit filter</div>
            </div>
          </div>
        </div>
      </div>

      <div class="elv-reconciliation-match-rule-container">
        <div class="elv-reconciliation-match-rule-header">
          <div class="elv-reconciliation-match-rule-header__title">{{ t('common.rules') }}:</div>
          <elv-button type="primary" plain round width="94" height="32" @click="onAddRule"
            ><SvgIcon name="source-add" width="16" height="16" />{{ t('button.addRule') }}</elv-button
          >
        </div>
        <RuleTable
          ref="tableRef"
          :ruleParams="ruleParams"
          :loading="loading"
          @onEditRule="onEditRule"
          @onChangePage="onChangePage"
          @onChangePageSize="onChangePageSize"
          @onChangeStatus="onChangeStatus"
          @onResetDetail="getReconciliationSetDetail"
        />
      </div>

      <div class="elv-reconciliation-match-rule-footer">
        <elv-button
          type="primary"
          round
          width="160"
          height="44"
          :loading="matchingLoading"
          :disabled="reconciliationSetDetail?.status !== 'NORMAL'"
          @click="onStartMatching"
          >{{ t('button.startMatching') }}</elv-button
        >
      </div>
    </section>

    <FilterDialog
      v-model:show="showFilterDialog"
      :currencyList="currencyList"
      :type="filterConditionsType"
      :chartOfAccount="chartOfAccount"
      :title="filterConditionsType === 'ledger' ? 'Accounting Ledger Filter' : 'Transactions Filter'"
      :entityAccountAuxiliaryTypeId="entityAccountAuxiliaryTypeId"
      :conditionsData="
        filterConditionsType === 'ledger'
          ? (reconciliationSetDetail?.ledgerConditions ?? [])
          : (reconciliationSetDetail?.transactionConditions ?? [])
      "
      @onResetDetail="getReconciliationSetDetail"
    />

    <RuleDialog
      v-model:show="showRuleDialog"
      :model="ruleDialogModel"
      :chartOfAccount="chartOfAccount"
      :currentRuleData="currentRuleData"
      :entityAccountAuxiliaryTypeId="entityAccountAuxiliaryTypeId"
      @onResetDetail="getReconciliationSetDetail"
    />
  </div>
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
import ConfigApi from '@/api/ConfigApi'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import Header from '../components/Header.vue'
import { CurrencyItemType } from '#/ReportsTypes'
import RuleTable from './components/RuleTable.vue'
import RuleDialog from './components/RuleDialog.vue'
import HeaderTabs from '../components/HeaderTabs.vue'
import ReconciliationApi from '@/api/ReconciliationApi'
import { useEntityStore } from '@/stores/modules/entity'
import FilterDialog from './components/FilterDialog.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import { formatNumber, formatNumberToSignificantDigits } from '@/lib/utils'
import FilterConditions from './components/FilterConditions/ViewContent.vue'

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const { entityDetail } = storeToRefs(entityStore)
const reconciliationStore = useReconciliationStore()
const { reconciliationSetDetail } = storeToRefs(reconciliationStore)

const ruleParams = ref({
  page: 1,
  limit: 20
})
const loading = ref(false)
const matchingLoading = ref(false)
const showFilterDialog = ref(false)
const showRuleDialog = ref(false)
const filterConditionsType = ref('')
const ruleDialogModel = ref('add')
const currentRuleData: any = ref({})
const entityAccountAuxiliaryTypeId = ref('')
const currencyList = ref<CurrencyItemType[]>([])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const reconciliationSetId = computed(() => {
  return String(route.params?.reconciliationSetId)
})

const chartOfAccount = computed(() => {
  return find(entityStore.chartOfAccountList, {
    chartOfAccountId: reconciliationSetDetail.value?.chartOfAccountId ?? ''
  })
})

const onEditFilter = (type: 'transaction' | 'ledger') => {
  filterConditionsType.value = type
  showFilterDialog.value = true
}

const onAddRule = () => {
  ruleDialogModel.value = 'add'
  currentRuleData.value = {}
  showRuleDialog.value = true
}

const onEditRule = (row: any) => {
  ruleDialogModel.value = 'edit'
  currentRuleData.value = row
  showRuleDialog.value = true
}

const onChangePageSize = (limit: number) => {
  ruleParams.value.limit = limit
  ruleParams.value.page = 1
}

const onChangePage = (page: number) => {
  ruleParams.value.page = page
}

// eslint-disable-next-line no-unused-vars
const onChangeStatus = async (row: any, status: any, index: number) => {
  try {
    loading.value = true
    if (status) {
      ElMessage.success(t('message.ruleEnabled'))
    } else {
      ElMessage.success(t('message.ruleDisabled'))
    }
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    loading.value = false
  }
}

const onStartMatching = async () => {
  try {
    matchingLoading.value = true
    await ReconciliationApi.executeReconciliationRule(entityId.value, reconciliationSetId.value)
    ElMessage.success(t('message.success'))
    loading.value = true
    await reconciliationStore.fetchReconciliationSetDetail(entityId.value, reconciliationSetId.value)
    loading.value = false
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    matchingLoading.value = false
    loading.value = false
  }
}

const filterDataInit = () => {
  try {
    const currencyData = new Promise((resolve, reject) => {
      ConfigApi.searchCurrencyList({ recommend: true, entityId: 0 })
        .then((res) => {
          currencyList.value = res.data
          return resolve(res.data)
        })
        .catch((e) => {
          return reject(e)
        })
    })
    const entityAccountAuxiliaryType = new Promise((resolve, reject) => {
      LedgerApi.getAuxiliaryTypeList(entityId.value, {
        page: 1,
        limit: 1
      })
        .then((res) => {
          entityAccountAuxiliaryTypeId.value = res.data.list[0].auxiliaryTypeId
          return resolve(res)
        })
        .catch((e) => {
          return reject(e)
        })
    })

    Promise.all([
      currencyData,
      entityAccountAuxiliaryType,
      accountStore.fetchEntityAccountList(entityId.value),
      accountStore.fetchCounterpartList(entityId.value, { page: 1, limit: 20 })
    ])
  } catch (error) {
    console.log(error)
  }
}

const getReconciliationSetDetail = async () => {
  try {
    loading.value = true
    await reconciliationStore.fetchReconciliationSetDetail(entityId.value, reconciliationSetId.value)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

watch(
  () => route,
  async () => {
    await getReconciliationSetDetail()
    filterDataInit()
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-reconciliation-match-detail-page {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.elv-reconciliation-match-content {
  flex: 1;
  min-height: 0;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 30px;

  .elv-reconciliation-match-rule-container {
    width: 100%;
    max-height: calc(100% - 163px);
    position: relative;
  }
}

.elv-reconciliation-match-information {
  display: flex;
  border-radius: 4px;
  border: 1px solid #ced7e0;
  margin-top: 20px;
  margin-bottom: 16px;

  .elv-reconciliation-match-table {
    flex: 1;
    flex-grow: 1;
    border-right: 1px solid #dde1e6;

    &:last-of-type {
      border-right: none;
    }
  }

  .elv-reconciliation-match-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px;
    height: 46px;
    box-sizing: border-box;
    width: 100%;
    background-color: #f9fafb;
    border-bottom: 1px solid #dde1e6;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  .elv-reconciliation-match-table-header__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    color: #1e2024;
    font-size: 14px;
    line-height: normal;
    display: flex;
    flex-direction: column;

    span {
      color: #838d95;
      font-size: 11px;
      font-weight: 500;
    }
  }

  .elv-reconciliation-match-table-header__total {
    display: flex;
    align-items: center;
  }

  .elv-reconciliation-match-table-header__total-label {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    color: #aaafb6;
    font-size: 14px;
    line-height: normal;
  }

  .elv-reconciliation-match-table-header__total-value {
    color: #1e2024;
    font-family: 'Barlow';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 4px;

    span {
      margin-left: 4px;
      font-family: 'Barlow';
      font-weight: 600;
      color: #1e2024;
      font-size: 14px;
      line-height: normal;
    }
  }

  .elv-reconciliation-match-table-body {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    width: 100%;
  }

  .elv-reconciliation-match-table-body__title {
    display: flex;
    gap: 16px;

    .elv-reconciliation-match-table-body__title-item {
      display: flex;
      font-family: 'Barlow';
      font-weight: 500;
      color: #0e0f11;
      font-size: 13px;
      line-height: normal;

      span {
        font-weight: 500;
        color: #0e0f11;
        font-size: 13px;
        letter-spacing: 0;
      }

      a {
        cursor: pointer;
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
  }

  .elv-reconciliation-match-table-body-conditions {
    margin-top: 8px;
    padding: 8px 10px;
    box-sizing: border-box;
    background-color: #f9fafb;
    border-radius: 3px;
    border: 1px solid #edf0f3;
    width: 100%;
    gap: 4px;
    display: inline-flex;
    flex-direction: column;

    .elv-reconciliation-match-table-condition {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      position: relative;
      flex: 0 0 auto;
    }

    .elv-reconciliation-match-table-condition__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      color: #636b75;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 16.5px;
    }

    .elv-reconciliation-match-table-condition__value {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      color: #0e0f11;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 16.5px;
      white-space: nowrap;
    }
  }

  .elv-reconciliation-match-table-button {
    display: flex;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    color: #1753eb;
    font-size: 13px;
    line-height: 24px;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      fill: #1753eb;
    }
  }
}

.elv-reconciliation-match-rule-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.elv-reconciliation-match-rule-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;

  .elv-reconciliation-match-rule-header__title {
    color: #000;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
